<template>
  <div id="app">
    <router-view v-if="isloaded"/>
    <MainFooter v-if="isloaded"/>
      <div class="page-loader" v-if="!isloaded">
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
      </div> 
  </div>
</template>

<script>
import MainFooter from '@/components/home/MainFooter'
export default {
   components: {
    MainFooter,
  },
  data(){
    return{
      isloaded:false
    }
  },
  mounted(){
    document.onreadystatechange=()=>{
      if(document.readyState =="complete"){
        this.isloaded=true
      }
    }
  },
  beforeCreate(){
   
  },
  metaInfo() {
        return { 
            title: "Best PTE Tutorials and PTE Tools for PTE Practice Online",
            meta: [
                { name: 'description', content:  'Start your Free PTE practice online now to get your desired 90 scores with the best PTE tools and PTE tutorials available, prepare yourself with a Free mock Test.'},
                { property: 'og:title', content: "PTE Classes | Best PTE Tutorials and PTE Tools for PTE Practice"},
                { property: 'og:site_name', content: 'PTE Classes'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'index,follow'}
            ]
        }
    }
  //Working on each click
  // mounted() {
  //   document.addEventListener("mouseup", e => {
  //     let m = `x: ${e.clientX} | y: ${e.clientY}`;
  //     ////console.log(m);
  //   })
  // }
}
</script>
<style>
/* @font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url(../src/assets/fonts/Montserrat.woff2) format("woff2");
   font-family: "MontserratBold";
    src: local("MontserratBold"),
    url(../src/assets/fonts/Montserrat.woff2) format("woff2");
} */
#id{
  width: 100vw;
  height: 100vh;
}
.list-group-item
{
 cursor: pointer;
padding: 10px 6px !important;
font-size: .8rem!important;
letter-spacing: 1px;
font-weight: 500;
}
.list-group-item:hover
{
  color: #1f4780;
font-weight: bold;
background: #eef1f8;
}
.results h6{
  display: inline;
}
.results p {
  display: inline;
padding-left: 5px;
}
.disabledRecording .ar-recorder div:first-child{
    pointer-events: none;
    opacity: 0.4;
}
.readPassage
{
float: right!important;
background: none!important;
border: 0px!important;
text-transform: capitalize;
margin-right: 15px!important;
}
.readPassage:hover, .readPassage:active{
  border: none!important;
}
.readPassage,.readPassage svg
{
color: #24d1d3;
}
.readPassage.play, .readPassage.play svg
{
  color: #db0f1b;
}
.resultModel{
border: 1px solid #ccc;
border-radius: 3px!important;
padding: 0!important;
}
.vue-js-switch
{
 display: inline-block !important;
margin-top: 3px;
}
/* headlines with lines */
.decorated{
     overflow: hidden;
     text-align: center;
 }
.decorated > span{
    position: relative;
    display: inline-block;
}
.decorated > span:before, .decorated > span:after{
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 2px solid;
    width: 100vw;
    margin: 0 20px;
}
.decorated > span:before{
    right: 100%;
}
.decorated > span:after{
    left: 100%;
}
.nav-item.dropdown.active.show > div.dropdown-menu.show{
      border-top: 5px solid;
    border-color: #195cc5;
}
.page-loader {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 position: fixed;
	 top: 0;
	 left: 0;
	 width: 100vw;
	 height: 100vh;
	 background-color: #333;
	 z-index: 999;
}
 .cube {
	 width: 40px;
	 height: 40px;
	 margin-right: 10px;
}
 .cube:nth-child(1) {
	 background-color: #8cc271;
}
 .cube:nth-child(2) {
	 background-color: #69beeb;
}
 .cube:nth-child(3) {
	 background-color: #f5aa39;
}
 .cube:nth-child(4) {
	 background-color: #e9643b;
}
 .cube:first-child {
	 animation: left 1s infinite;
}
 .cube:last-child {
	 animation: right 1s infinite 0.5s;
}

</style>
<style scoped>

 @keyframes left {
	 40% {
		 transform: translateX(-60px);
	}
	 50% {
		 transform: translateX(0);
	}
}
 @keyframes right {
	 40% {
		 transform: translateX(60px);
	}
	 50% {
		 transform: translateX(0);
	}
}
.ytp-chrome-top-buttons{
    display: none!important;
}
</style>